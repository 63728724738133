@charset "UTF-8";
@import '~swiper/scss';
@import '~swiper/scss/a11y';
@import '~swiper/scss/effect-fade';
@import '~swiper/scss/manipulation';
@import '~swiper/scss/navigation';
@import '~swiper/scss/pagination';
@import '~swiper/scss/scrollbar';
@import '~swiper/scss/zoom';
@import '~bootstrap/scss/functions';
@import 'variables';
@import 'mixins/focus-visible';
@import 'bootstrap-overrides';
@import 'mixins/index';
@import '~bootstrap/scss/bootstrap';

// BS v4 -> v5 shims

@import './utilities/screenreaders';

.btn-block {
    display: block;

    width: 100%;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.sr-only {
    @include visually-hidden;
}

.sr-only-focusable {
    @include visually-hidden-focusable;
}

// end shims

.fw-thin {
    font-weight: $font-weight-light !important;
}

.fw-regular {
    font-weight: $font-weight-normal !important;
}

.fw-heavy {
    font-weight: $font-weight-heavy !important;
}

:root {
    --pata-font-sans: #{$font-sans};
    --pata-font-serif: #{$font-serif};
    --pata-font-special: #{$font-special};
    --pata-font-monospace: #{$font-monospace};
    --pata-font-sans-light-ja: #{$font-light-ja};
    --pata-font-sans-regular-ja: #{$font-regular-ja};
    --pata-font-sans-medium-ja: #{$font-medium-ja};
    --pata-font-sans-demi-ja: #{$font-demi-ja};
    --pata-font-sans-bold-ja: #{$font-bold-ja};
}

:lang(ja):root {
    --bs-body-font-family: #{$font-demi-ja};
}

:lang(ja) {
    .btn {
        --bs-btn-font-weight: 700;
    }
}
