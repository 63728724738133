/* stylelint-disable scss/operator-no-newline-after */

@use 'sass:map';
@use 'sass:math';
@use 'sass:string';
@import '~bootstrap/scss/functions';
@import './mixins/breakpoints';
@import 'animation-variables';

// font-awesome font file locations in relation to target location of the css file.
$fa-font-path: '../fonts' !default;

// flag icons location in relation to target location of the css file.
$flag-icon-css-path: '../fonts/flags/' !default;

////////////////////////////////////////////////////////
// Colors
////////////////////////////////////////////////////////

$white: #fff;
$black: #000;
$red: #c00;

// brand colors
$red-brand: #fa4616;
$purple-brand: #500778;
$blue-brand: #003da5;

// bright colors
$purple-minsk: #342e7d;
$green-utility: #32b67a;
$blue-utility: #91abe9;
$yellow-utility: #feb904;
$red-utility: #e10000;
$orange-utility: #f3775a;
$grey-blue: #e5ebf6;

// $orange-utility-2: #e5801a;

// ww colors
$orange-brand-ww: #db5f3c;
$tan-brand-ww: #f6d2a4;
$brown-brand-ww: #603c19;
$beige-brand-ww: #fff7e7;
$wornwear-beige: $beige-brand-ww;

// 50th
$teal-fiftieth: #97d4c3;
$blue-fiftieth: #447fa2;
$purple-fiftieth: #555270;
$coral-fiftieth: #b66d5e;

// 50th S23 recommended
$amaranth-pink: #903f75;
$dragon-purple: #e1b2d3;
$rosewater: #e3c1b0;
$isla-yellow: #ededb7;
$shine-yellow: #e4bf45;
$salvia-green: #a8b197;
$sage-khaki: #766d53;
$bloom-green: #76b583;
$early-teal: #87d8c3;
$hemlock-green: #536657;
$plume-grey: #58646d;
$steam-blue: #a3b4c4;
$lagom-blue: #123955;
$husk-tan: #cab698;
$trip-brown: #ac8266;
$cone-brown: #56443f;

// 50th F23 recommended
$touring-red: #a11729;
$carmine-red: #5d222b;
$mystery-mauve: #884c5e;
$cozy-peach: #dbbeb7;
$campfire-orange: #f47b29;
$dried-mango: #ca9456;
$surfboard-yellow: #dab965;
$shrub-green: #a19436;
$shale: #91907e;
$steppe-green: #61845b;
$industrial-green: #485643;
$belay-blue: #006979;
$pale-periwinkle: #919bc9;
$tailored-grey: #bbbebd;

// brand colors
$brand-colors: (
    'red-brand': $red-brand,
    'purple-brand': $purple-brand,
    'blue-brand': $blue-brand,
    'blue-utility': $blue-utility,
    'red-utility': $red-utility,
    'yellow-utility': $yellow-utility,
    'green-utility': $green-utility,
    'grey-blue-utility': $grey-blue,
    'purple-minsk': $purple-minsk,
    'orange-brand-ww': $orange-brand-ww,
    'tan-brand-ww': $tan-brand-ww,
    'brown-brand-ww': $brown-brand-ww,
    'teal-fiftieth': $teal-fiftieth,
    'blue-fiftieth': $blue-fiftieth,
    'purple-fiftieth': $purple-fiftieth,
    'coral-fiftieth': $coral-fiftieth,
    'amaranth-pink': $amaranth-pink,
    'dragon-purple': $dragon-purple,
    'rosewater': $rosewater,
    'isla-yellow': $isla-yellow,
    'shine-yellow': $shine-yellow,
    'salvia-green': $salvia-green,
    'sage-khaki': $sage-khaki,
    'bloom-green': $bloom-green,
    'early-teal': $early-teal,
    'hemlock-green': $hemlock-green,
    'plume-grey': $plume-grey,
    'steam-blue': $steam-blue,
    'lagom-blue': $lagom-blue,
    'husk-tan': $husk-tan,
    'trip-brown': $trip-brown,
    'cone-brown': $cone-brown,
    'touring-red': $touring-red,
    'carmine-red': $carmine-red,
    'mystery-mauve': $mystery-mauve,
    'cozy-peach': $cozy-peach,
    'campfire-orange': $campfire-orange,
    'dried-mango': $dried-mango,
    'surfboard-yellow': $surfboard-yellow,
    'shrub-green': $shrub-green,
    'shale': $shale,
    'steppe-green': $steppe-green,
    'industrial-green': $industrial-green,
    'belay-blue': $belay-blue,
    'pale-periwinkle': $pale-periwinkle,
    'tailored-grey': $tailored-grey,
);

// greytones
$grey-darkest: #121212;
$grey-darker: #222;
$grey-dark: #333;
$grey-tundora: #4a4a4a;
$grey: #999;
$grey-silver: #ccc;
$grey-baked: #e1e1e1;
$grey-gallery: #eaeaea;
$grey-fpc: #f7f6f4;
$grey-light: #999990;
$white-concrete: #f2f2f2;
$grey-white: #f5f5f5;
$white-alabaster: #fafafa;
$grey-productimg: #f6f6f6;
$grey-yellow: #f3f4ef;

// selection colors
$selection-bg-color: $white;
$selection-text-color: $black;

// external brand colors
$paypal-brand: #009cde;
$blue: #0070d2 !default;
$green: #008827 !default;
$light-blue: #7ed0ee !default;

// Consolidated values
$grey1: #f9f9f9 !default;
$grey2: #eee !default;
$grey3: #ccc !default;
$grey4: #999 !default;
$grey5: #666 !default;
$grey6: #444 !default;
$grey7: #222 !default;
$grey8: #333 !default;
$grey-transparent-1: rgba(0, 0, 0, 0.65) !default;
$grey-transparent-2: rgba(0, 0, 0, 0.25) !default;
$light-gray: $grey1 !default;
$slightly-darker-gray: $grey4 !default;
$dark-gray: $grey6 !default;
$darker-gray: $grey7 !default;
$horizontal-rule-grey: $grey3 !default;
$product-number-grey: $grey3 !default;
$horizontal-border-grey: $grey4 !default;
$menu-link: $grey6 !default;
$close-menu-bg: $grey2 !default;
$link-color: $dark-gray !default;
$hr-border-color: $grey3 !default;

// Font sizes
$base16-14px: 0.875em !default;

// common border
$border-gray: 1px solid $grey-baked;

// overlays
$overlay-opacity: 0.4 !default;
$overlay-hero: rgba(0, 0, 0, $overlay-opacity) !default;

// alert colors
$alert-info: #d1e3fc;
$alert-warning: #fce9c7;
$alert-success: #cff6df;
$alert-danger: #f7d1cc;

////////////////////////////////////////////////////////
// Breakpoints
////////////////////////////////////////////////////////

$breakpoints: (
    'xsmall': 360px,
    'tiny': 480px,
    'small': 576px,
    'medium': 768px,
    'large': 992px,
    'xlarge': 1200px,
    'xxlarge': 1400px,
    'xxxlarge': 1600px,
    'xxxxlarge': 1800px,
);

// Custom Breakpoint Requested, only show new sidebar at 1025px or higher.
// Modal version should be shown for anything up to, and including 1024px
$sidebar-breakpoint: 1025px;

// interaction types
// check https://caniuse.com/#feat=css-media-interaction before using
// how to use: https://css-tricks.com/touch-devices-not-judged-size/
$hover-mq: 'hover: hover';
$no-hover-mq: 'hover: none';
$fine-pointer-mq: 'pointer: fine';
$coarse-pointer-mq: 'pointer: coarse';

////////////////////////////////////////////////////////
// Typography
////////////////////////////////////////////////////////

$font-family-sans-serif: 'system-ui,'; // Cross-platform generic font family (default user interface font)
// Safari for macOS and iOS (San Francisco)
$font-family-sans-serif: $font-family-sans-serif + '-apple-system,';

// Windows
$font-family-sans-serif: $font-family-sans-serif + "'Segoe UI',";

// Android
$font-family-sans-serif: $font-family-sans-serif + 'Roboto,';

// Basic web fallback
$font-family-sans-serif: $font-family-sans-serif + "'Helvetica Neue', Arial,";

// Linux
$font-family-sans-serif: $font-family-sans-serif + "'Noto Sans','Liberation Sans',";

// Sans serif fallback
$font-family-sans-serif: $font-family-sans-serif + 'sans-serif,';

// Emoji fonts
$font-family-sans-serif: $font-family-sans-serif +
    "'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'";
$font-sans-serif: $font-family-sans-serif !default;
$font-monospace: string.unquote(
    "SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New'," + 'monospace '
) !default;
$font-base: string.unquote($font-sans-serif) !default;
$font-sans: string.unquote("'Ridgeway Sans'," + $font-base);
$font-light: string.unquote("'Ridgeway Sans'," + $font-base);
$font-demi: string.unquote("'Ridgeway Sans'," + $font-base);
$font-medium: string.unquote("'Ridgeway Sans'," + $font-base);
$font-bold: string.unquote("'Ridgeway Sans'," + $font-base);
$font-special: string.unquote("'BT Belwe W01'," + $font-base);
$font-special-copernicus: string.unquote(
    "'Copernicus', 'Palatino Linotype', Palatino, Palladio, 'URW Palladio L'," +
        "'Book Antiqua', Baskerville, 'Bookman Old Style', 'Bitstream Charter', 'Nimbus Roman No9 L'," +
        "Garamond, 'Apple Garamond', 'ITC Garamond Narrow', 'New Century Schoolbook'," +
        "'Century Schoolbook', 'Century Schoolbook L', Georgia, serif;"
);
$font-special-lusitana: $font-special-copernicus;
$font-body: $font-medium;
$font-body-bold: $font-bold;
$font-heading: $font-bold;
$font-serif: $font-special-copernicus;

// Legacy font, set to $font-serif if missed
$font-special-sentinel: $font-serif;

// JP fonts
$font-light-ja: "'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro W3', 'ヒラギノ角ゴ Pro'," +
    "'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'Hiragino Kaku Gothic', 'Yu Gothic(游ゴシック)'," +
    "'游ゴシック', '游ゴシック体', YuGothic, 'Yu Gothic', Osaka, 'メイリオ', Meiryo, 'Hiragino Sans'," +
    $font-family-sans-serif;
$font-regular-ja: "'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro W3', 'ヒラギノ角ゴ Pro'," +
    "'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'Hiragino Kaku Gothic', '游ゴシック Medium'," +
    "'游ゴシック体', YuGothic, 'Yu Gothic Medium', Osaka, 'メイリオ', Meiryo, 'Hiragino Sans'," +
    $font-family-sans-serif;
$font-medium-ja: "'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro W3', 'ヒラギノ角ゴ Pro'," +
    "'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'Hiragino Kaku Gothic', '游ゴシック Medium'," +
    "'游ゴシック体', YuGothic, 'Yu Gothic Medium', Osaka, 'メイリオ', Meiryo, 'Hiragino Sans'," +
    $font-family-sans-serif;
$font-demi-ja: "'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro W3', 'ヒラギノ角ゴ Pro'," +
    "'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'Hiragino Kaku Gothic', 'Yu Gothic(游ゴシック)'," +
    "'游ゴシック', '游ゴシック体', YuGothic, 'Yu Gothic', Osaka, 'メイリオ', Meiryo, 'Hiragino Sans'," +
    $font-family-sans-serif;
$font-bold-ja: "'ヒラギノ角ゴ Pro W6', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro W6'," +
    "'ヒラギノ角ゴ Pro', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'Hiragino Kaku Gothic'," +
    "'游ゴシック Medium', '游ゴシック体', YuGothic, 'Yu Gothic Medium', Osaka, 'メイリオ', Meiryo," +
    "'Hiragino Sans'," + $font-family-sans-serif;
$font-skinny-bold-ja: "'ヒラギノ角ゴ Pro W6', 'Hiragino Kaku Gothic Pro W6', 'ヒラギノ角ゴ Pro'," +
    "'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ', 'Hiragino Kaku Gothic', '游ゴシック Medium'," +
    "'游ゴシック体', YuGothic, 'Yu Gothic Medium', Osaka, 'メイリオ', Meiryo, 'Hiragino Sans'," +
    $font-family-sans-serif;
$font-light-ja: string.unquote($font-light-ja);
$font-regular-ja: string.unquote($font-regular-ja);
$font-medium-ja: string.unquote($font-medium-ja);
$font-demi-ja: string.unquote($font-demi-ja);
$font-bold-ja: string.unquote($font-bold-ja);
$font-skinny-bold-ja: string.unquote($font-skinny-bold-ja);

// rem reset to 10px per 1rem
$rem-base: 10px !default;

// default font settings

$font-size: 1.4rem;
$letter-spacing: 0;
$line-height: 1.75;
$font-weight: 400;

// headings settings
$heading-letter-spacing: -0.02em;
$heading-letter-spacing-serif: -0.05em;
$heading-line-height: 1.1;
$heading-mobile-sizes: (
    'h1': 4rem,
    'h2': 3.2rem,
    'h3': 2.8rem,
    'h4': 2.4rem,
    'h5': 2rem,
    'h6': 1.6rem,
    'h7': 1.4rem,
    'h8': 1.2rem,
    'h9': 1.2rem,
    'h10': 1.2rem,
);
$heading-desktop-sizes: (
    'h1': 6.4rem,
    'h2': 4.8rem,
    'h3': 4rem,
    'h4': 3.2rem,
    'h5': 2.8rem,
    'h6': 2.4rem,
    'h7': 2rem,
    'h8': 1.6rem,
    'h9': 1.4rem,
    'h10': 1.2rem,
);
$heading-mobile-sizes-jp: (
    'h1': map.get($heading-mobile-sizes, 'h1') * 0.9,
    'h2': map.get($heading-mobile-sizes, 'h2') * 0.9,
    'h3': map.get($heading-mobile-sizes, 'h3') * 0.95,
    'h4': map.get($heading-mobile-sizes, 'h4') * 0.95,
    'h5': map.get($heading-mobile-sizes, 'h5'),
    'h6': map.get($heading-mobile-sizes, 'h6'),
    'h7': map.get($heading-mobile-sizes, 'h7'),
    'h8': map.get($heading-desktop-sizes, 'h8'),
    'h9': map.get($heading-desktop-sizes, 'h9'),
    'h10': map.get($heading-desktop-sizes, 'h10'),
);
$heading-desktop-sizes-jp: (
    'h1': map.get($heading-desktop-sizes, 'h1') * 0.8,
    'h2': map.get($heading-desktop-sizes, 'h2') * 0.8,
    'h3': map.get($heading-desktop-sizes, 'h3') * 0.85,
    'h4': map.get($heading-desktop-sizes, 'h4') * 0.9,
    'h5': map.get($heading-desktop-sizes, 'h5') * 0.9,
    'h6': map.get($heading-desktop-sizes, 'h6') * 0.95,
    'h7': map.get($heading-desktop-sizes, 'h7') * 0.95,
    'h8': map.get($heading-desktop-sizes, 'h8'),
    'h9': map.get($heading-desktop-sizes, 'h9'),
    'h10': map.get($heading-desktop-sizes, 'h10'),
);

// jumbo settings
$jumbo-mobile-sizes: (
    'jumbo1': 8rem,
    'jumbo2': 6.4rem,
);
$jumbo-desktop-sizes: (
    'jumbo1': 12.8rem,
    'jumbo2': 9.6rem,
);
$jumbo-mobile-sizes-jp: (
    'jumbo1': map.get($jumbo-mobile-sizes, 'jumbo1') * 0.8,
    'jumbo2': map.get($jumbo-mobile-sizes, 'jumbo2') * 0.8,
);
$jumbo-desktop-sizes-jp: (
    'jumbo1': map.get($jumbo-desktop-sizes, 'jumbo1') * 0.8,
    'jumbo2': map.get($jumbo-desktop-sizes, 'jumbo2') * 0.8,
);

// paragraph settings
$body-letter-spacing: 0;
$body-letter-spacing-serif: -0.03em;
$body-line-height: 1.5;
$body-mobile-sizes: (
    'p-xs': 1.2rem,
    'p-sm': 1.2rem,
    'p': 1.4rem,
    'p-lg': 1.6rem,
    'p-xl': 2rem,
);
$body-desktop-sizes: (
    'p-xs': 1.2rem,
    'p-sm': 1.4rem,
    'p': 1.6rem,
    'p-lg': 2rem,
    'p-xl': 2.8rem,
);
$body-mobile-sizes-jp: (
    'p-xs': map.get($body-mobile-sizes, 'p-xs'),
    'p-sm': map.get($body-mobile-sizes, 'p-sm'),
    'p': map.get($body-mobile-sizes, 'p'),
    'p-lg': map.get($body-mobile-sizes, 'p-lg') * 0.95,
    'p-xl': map.get($body-mobile-sizes, 'p-xl') * 0.95,
);
$body-desktop-sizes-jp: (
    'p-xs': map.get($body-desktop-sizes, 'p-xs'),
    'p-sm': map.get($body-desktop-sizes, 'p-sm'),
    'p': map.get($body-desktop-sizes, 'p'),
    'p-lg': map.get($body-desktop-sizes, 'p-lg') * 0.95,
    'p-xl': map.get($body-desktop-sizes, 'p-xl') * 0.95,
);

////////////////////////////////////////////////////////
// Z-Index Stacking Order
////////////////////////////////////////////////////////

$z-indexes: (
    'add-to-cart': 19,
    'cursor': 50,
    'hero': 60,
    'header': 200,
    'subnav': 290,
    'sticky': 300,
    'styleguide-border': 350,
    'navigation-expanded': 400,
    'cart': 450,
    'modal': 500,
    'top': 1000,
    'styleguide-controls': 2000,
    'styleguide-top': 3000,
);

////////////////////////////////////////////////////////
// Spacing
////////////////////////////////////////////////////////

$icon-space: 3rem;

////////////////////////////////////////////////////////
// Pages w/o hero margins
////////////////////////////////////////////////////////
$no-hero-margin-top: 4rem;
$no-hero-margin-bottom: 12rem;
$no-hero-margin-top-mobile: 1.6rem;
$no-hero-margin-bottom-mobile: 8rem;
$no-hero-margin-bottom-mobile-small: 4rem;
$no-hero-margin-bottom-mobile-x-small: 1rem;

// swiper variable
$themeColor: $black; /* stylelint-disable-line scss/dollar-variable-pattern */

// Transition Time for Animations
$transition-time: 0.15s;
