@import './bootstrap-override-variables';

.card {
    border: none;
}

.card-footer {
    border-top: none;

    background-color: transparent;
}

// Fix for Button Hover Text Blur
.btn {
    transform: perspective(1px) scale3d(1, 1, 1) translate3d(0, 0, 0);

    transition: transform 0.2s cubic-bezier(0.235, 0, 0.05, 0.95);
    -webkit-font-smoothing: subpixel-antialiased;
    text-rendering: geometricPrecision;

    span,
    figure {
        -webkit-font-smoothing: subpixel-antialiased;
        text-rendering: geometricPrecision;
    }

    &:not(.btn-text-only, .cta-link-underline, .btn-underline, .btn-deep-underline) {
        &:hover {
            // Using 1.045 rather than 1.05 to make sure font scales to a size it can render at without blur
            transform: perspective(1px) scale3d(1.044, 1.044, 1) translate3d(0, 0, 0) !important;
        }

        &:active {
            box-shadow:
                0 4px 5px rgba(0, 0, 0, 0.14),
                0 1px 10px rgba(0, 0, 0, 0.12),
                0 2px 4px -1px rgba(0, 0, 0, 0.2);
        }
    }
}

.btn-dark,
.btn-light,
.btn-dark.btn-outlined,
.btn-light.btn-outlined {
    @include focus-visible {
        border-color: $blue-utility;
    }
}

.btn-dark.btn-outlined,
.btn-light.btn-outlined {
    background-color: transparent;
}

.breadcrumb-item {
    @include focus-visible {
        li a {
            outline: 0.2rem $blue-utility auto;
        }
    }
}

.row > * {
    position: relative;
}

// Button focus state overrides
.btn,
.btn-light.btn-outlined,
.btn-dark.btn-outlined,
.cta-circle {
    @include focus-visible {
        border-color: $blue-utility;
    }
}

.dark-theme {
    .btn.btn-dark,
    .btn.btn-dark:focus,
    .btn.btn-dark.btn-outlined,
    .btn.btn-dark.btn-outlined:focus,
    .btn.btn-light,
    .btn.btn-light:focus,
    .btn.btn-light.btn-outlined,
    .btn.btn-light.btn-outlined:focus,
    .cta-circle {
        @include focus-visible {
            border-color: $blue-utility;
        }
    }
}

.btn.btn-light.btn-outlined,
.btn.btn-dark.btn-outlined {
    &:focus {
        background-color: transparent;
    }
}

// override badges
.badge {
    display: inline-flex;
    justify-content: center;
    align-items: baseline;

    padding: $badge-padding;

    font-size: 1.2rem;

    border-radius: $badge-border-radius;

    span {
        font-size: inherit;
        line-height: $badge-line-height;
        vertical-align: baseline;
    }
}
